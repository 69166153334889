<template>
  <MainTableComponent
    ref="table"
    :row-count="tableRowCount"
    :statuses-config="$route.query"
    :filters-from-parent="filters"
    state-key-name="task-list-state"
    :row-click-function="goToTask"
    :show-save-filters-button="true"
    :is-purchasing="true"
  />
  <ScrollTopArrow />
</template>

<script>
import ScrollTopArrow from "../TableComponents/ScrollTopArrow";
import MainTableComponent from "../TableComponents/MainTableComponent";

export default {
  name: "TaskList",
  components: {
    MainTableComponent,
    ScrollTopArrow,
  },
  data() {
    return {
      tableRowCount: 20,
      filters: {
        id: { value: null },
        companyName: { value: null },
        companyDistrict: { value: [] },
        techLabel: { value: null },
        header: { value: null },
        author: { value: null },
        createdDateForFiltering: {
          value: {
            range: {
              //Вчерашняя дата
              start: new Date("2023/03/05"),
              end: new Date(),
            },
          },
        },
        revisionRoles: { value: [] },
        checked: { value: [] },
        statusPtc: { value: [] },
        statusText: { value: [] },
        taskWorkType: { value: [] },
        critical: { value: [] },
        checklistType: { value: [] },
        mainEngineer: { value: [] },
        regionUids: { value: [] },
      },
    };
  },
  watch: {
    "$route.query.technic": function (filter) {
      if (filter) {
        this.$refs.table.loadFilteredTasks();
      }
    },
    "$route.query.search": function (filter) {
      if (filter) {
        this.$refs.table.searchByNumber(filter);
      }
    },
  },
  mounted: async function () {
    this.checkForSearching();
  },
  methods: {
    checkForSearching() {
      if (this.$route.query.search) {
        this.$refs.table.searchByNumber(this.$route.query.search);
      }
      if (this.$route.query.technic) {
        this.$refs.table.loadFilteredTasks();
      }
    },

    goToTask(event) {
      this.$router.push(`/task/${event.data.id}`);
    },
  },
};
</script>

<style scoped lang="scss"></style>
